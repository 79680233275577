<template>
	<div class="pages-aboutAPP">
		<img class="img-p" :src="require('@/assets/images/temp5_top.png')" />
		
		<div class="bgbox-white">
			<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 20, offset: 2 }" :lg="{ span: 16, offset: 4 }">
				<div class="module1-conts">
					<div class="module-title">
						<span class="title-cn">云南德合APP</span>
						<span class="title-en">DEHE BUSINESS SERVICE APP</span>
						<span class="title-txt">支持多种支付收款工具，无需对接开发，无需更换设备</span>
					</div>
				</div>
			</el-col>
			
			<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:24 }">
				<div class="wow slideInLeft" style="width: 100%;display: flex;flex-direction: row;justify-content: center;align-items: center;">
					<img style="" :src="require('@/assets/images/temp5_02.png')" />
				</div>
			</el-col>
			
			<!-- <el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 20, offset: 2 }" :lg="{ span: 16, offset: 4 }"> -->
				<div class="module1-conts" style="background: #E5F4FC;width: 100%;">
					<div class="module-title">
						<span class="title-txt">我们优势</span>
					</div>
					<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:16, offset: 4 }">
						<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 12 }" :lg="{ span:4 }">
							<div class="flexcc">
								<div class="numview">1</div>
								<div class="txt">聚合收款</div>
							</div>
						</el-col>
						<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 12 }" :lg="{ span:4 }">
							<div class="flexcc">
								<div class="numview">2</div>
								<div class="txt">经营数据一目了然</div>
							</div>
						</el-col>
						<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 12 }" :lg="{ span:4 }">
							<div class="flexcc">
								<div class="numview">3</div>
								<div class="txt">多功能会员营销</div>
							</div>
						</el-col>
						<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 12 }" :lg="{ span:4 }">
							<div class="flexcc">
								<div class="numview">4</div>
								<div class="txt">多门店经营一手掌控</div>
							</div>
						</el-col>
						<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 12 }" :lg="{ span:4 }">
							<div class="flexcc">
								<div class="numview">5</div>
								<div class="txt">行业资讯实时更新</div>
							</div>
						</el-col>
						<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 12 }" :lg="{ span:4 }">
							<div class="flexcc">
								<div class="numview">6</div>
								<div class="txt">商户成长智慧规划</div>
							</div>
						</el-col>
					</el-col>
				</div>
			<!-- </el-col> -->
			
		</div>
		
		<div class="bgbox-white">
			<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 20, offset: 2 }" :lg="{ span: 16, offset: 4 }">
				<div class="module1-conts">
					<div class="module-title">
						<span class="title-cn">客户管理后台</span>
						<span class="title-en">MANAGEMENT BACKGROUND</span>
						<span class="title-txt">依托智慧经营体系，全方位助力营销。只需安装一个应用、收款、核销、电子会员一键搞定</span>
					</div>
				</div>
			</el-col>
			
			<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 20, offset: 2 }" :lg="{ span: 16, offset: 4 }">
				<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:8 }">
					<div class="core-css01 slideInLeft">
						<div class="core-txt">
							<li class="title2">聚合收款</li>
						</div>
					</div>
				</el-col>
				<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:8 }">
					<div class="core-css01 slideInLeft">
						<div class="core-txt">
							<li class="title2">小票打印</li>
						</div>
					</div>
				</el-col>
				<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:8 }">
					<div class="core-css01 slideInLeft">
						<div class="core-txt">
							<li class="title2">支持花呗分期</li>
						</div>
					</div>
				</el-col>
				<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:8 }">
					<div class="core-css01 slideInLeft">
						<div class="core-txt">
							<li class="title2">预授权交易</li>
						</div>
					</div>
				</el-col>
				<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:8 }">
					<div class="core-css01 slideInLeft">
						<div class="core-txt">
							<li class="title2">交易明细查询</li>
						</div>
					</div>
				</el-col>
				<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:8 }">
					<div class="core-css01 slideInLeft">
						<div class="core-txt">
							<li class="title2">交接班数据汇总</li>
						</div>
					</div>
				</el-col>
			</el-col>
			
			
			<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:24 }">
				<div class="wow slideInLeft" style="width: 100%;display: flex;flex-direction: row;justify-content: center;align-items: center;">
					<img style="" :src="require('@/assets/images/temp5_01.png')" />
				</div>
			</el-col>
			
				<div class="module1-conts" style="background: #E5F4FC;width: 100%;">
					<div class="module-title">
						<span class="title-txt">我们优势</span>
					</div>
					<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:16, offset: 4 }">
						<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 12 }" :lg="{ span:6 }">
							<div class="flexcc">
								<div class="numview">1</div>
								<div class="txt">一键安装 ,功能齐全</div>
							</div>
						</el-col>
						<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 12 }" :lg="{ span:6 }">
							<div class="flexcc">
								<div class="numview">2</div>
								<div class="txt">一键生成财务报表 ,轻松对账</div>
							</div>
						</el-col>
						<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 12 }" :lg="{ span:6 }">
							<div class="flexcc">
								<div class="numview">3</div>
								<div class="txt">责任到人 ,高效管理</div>
							</div>
						</el-col>
						<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 12 }" :lg="{ span:6 }">
							<div class="flexcc">
								<div class="numview">4</div>
								<div class="txt">支持多门店管理 ,省时省心</div>
							</div>
						</el-col>
					</el-col>
				</div>
		</div>
		
		
	</div>
</template>

<script>

	export default {
		components: {
			
		},
		data() {
			let vm = this
			return {
				showBigImg: false,
				bigImgSrc: null,
				swiperOptionCard: {
					slidesPerView: 4, //显示数量
					spaceBetween: 10,
					centeredSlides: false, //居中slide
					loop: false,
					autoplay: false,
					breakpoints: {
						640: {
							slidesPerView: 1,
							spaceBetween: 0,
						},
						768: {
							slidesPerView: 2,
							spaceBetween: 20,
						},
						992: {
							slidesPerView: 3,
							spaceBetween: 20,
						}
					},
					pagination: {
						el: '.swiper-card-pagination',
						clickable: true,
					},
					navigation: {
						nextEl: '.swiper-card-button-next',
						prevEl: '.swiper-card-button-prev',
					}
				},
				swiperOptionCompany: {
					slidesPerView: 4, //显示数量
					spaceBetween: 20,
					centeredSlides: true,
					loop: true,
					autoplay: {
						delay: 5000,
						disableOnInteraction: false //点击切换后 停止继续自动播放
					},
					breakpoints: {
						640: {
							slidesPerView: 1,
							spaceBetween: 0,
						},
						768: {
							slidesPerView: 4,
							spaceBetween: 30,
						}
					},
					navigation: {
						nextEl: '.swiper-company-button-next',
						prevEl: '.swiper-company-button-prev',
					},
					on: {
						click: function() {
							if (this.clickedSlide) {
								vm.bigImgSrc = this.clickedSlide.getElementsByTagName('img')[0].getAttribute('src');
								vm.showBigImg = true;
								// console.log(this.clickedSlide.getAttribute('data-swiper-slide-index'));
								// console.log(this);
							}
						}
					}
				},
				center: {
					lng: 0,
					lat: 0
				},
				zoom: 3
			};
		},
		methods: {
			maphandler() {
				this.center.lng = 113.036666
				this.center.lat = 28.145000
				this.zoom = 18
			}
		}
	};
</script>
<style lang="scss">
	.BMap_cpyCtrl,
	.anchorBL {
		display: none;
	}

	.BMapLabel {
		padding: 6px !important;
		border: 1px solid #0066cc !important;
		color: #0066cc !important;
		left: -45px !important;
		top: -30px !important;
		font-weight: bold !important;
	}

	// big img
	.dialog-bigimg {
		box-shadow: none;
		background: transparent;
		margin: 5% auto 0 !important;
		width: fit-content !important;
		height: auto !important;
		overflow: hidden;

		.el-dialog__header {
			display: none;
		}

		.el-dialog__body {
			padding: 0;
			width: fit-content !important;
			height: auto !important;

			img {
				max-width: 100%;
				max-height: 100%;
				margin: 0 auto;
				display: block;
			}
		}
	}
</style>
<style lang="scss" scoped>
	.img-p{
		width: 100%;
	}
	.contact-img{
		width: 100%;
	}
	.article {
		font-size: 16px;
		text-align: center;
		padding: 0 10px;
	}

	.card-show {
		overflow: hidden;

		.item {
			float: left;
			width: 100%;
			margin: 30px 0px 0px 0px;
			// background: #ff5500;
			padding: 20px 10px 20px 10px;
			box-sizing: border-box;
			transition: all 0.3s ease;

			h3 {
				font-size: 20px;
				color: #333;
				padding-bottom: 5px;
			}

			h4 {
				height: 30px;
				font-size: 13px;
				color: #333;
				font-weight: 400;
				margin-bottom: 20px;
			}

			p {
				height: 60px;
				font-size: 16px;
				color: #666;
				margin: 20px 0;
			}

			.img-box {
				width: 100%;
				max-height: 390px;
				overflow: hidden;

				img {
					max-width: 471px;
					max-height: 390px;
					object-fit: cover;
					transition: all 0.3s ease;
				}
			}
		}

		.item:hover {
			cursor: pointer;
			// box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
			transform: translate3d(0, -2px, 0);

			img {
				transform: scale(1.1);
			}

			.el-button {
				color: #409EFF;
				border-color: #c6e2ff;
				background-color: #ecf5ff;
			}
		}

		.item:last-child {
			margin-right: 0;
		}

		.swiper-button-prev,
		.swiper-button-next {
			outline: none;
			height: 30px;
		}
	}
	.core-css01{
		margin-top: 20px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		
		.core-txt{
			width: 200px;
			font-size: 20px;
		}
	}
	.flexcc{
		margin-top: 20px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-bottom: 30px;
		
		.numview{
			color: #fff;
			background: linear-gradient(90deg, #E5B760 0%, #E5B760 45.18%, #D76923 100%);
			border-radius: 50%;
			width: 40px;
			height: 40px;
			line-height: 40px;
			text-align: center;
			font-size: 18px;
			font-weight: 700;
		}
		
		.txt{
			margin-top: 10px;
			font-size: 18px;
			color: #4C4C4D;
		}
	}
	.bgbox-white {
		padding: 50px 0px;
		overflow: hidden;
		background: #fff;
		box-sizing: border-box;
	}

	.the-banner {
		height: 400px;
		background-repeat: no-repeat;
		background-position: center;
	}


	.module1-conts {
		display: flex;
		flex-direction: column;
		justify-content: center;
		p {
			font-size: 16px;
			color: #333;
			text-indent: 2em;
		}
		.module-title {
			text-align: center;
			padding-bottom: 20px;

			.title-cn {
				display: block;
				font-size: 30px;
				font-weight: bold;
				color: #1f1f1f;
			}

			.title-txt {
				margin-top: 20px;
				display: block;
				font-size: 18px;
				font-weight: bold;
				color: #1f1f1f;
			}

			.title-en {
				display: block;
				font-size: 12px;
				font-weight: 400;
				color: #757575;
				line-height: 20px;
			}
		}
	}


	// 移动端css
	@media screen and (max-width: 767px) {	
		
		.module-title {	
			text-align: center;
			padding-bottom: 20px;
			
			
			.title-cn {
				display: block;
				font-size: 30px;
				font-weight: bold;
				color: #1f1f1f;
			}
			
			.title-txt {
				margin-top: 20px;
				display: block;
				font-size: 18px;
				font-weight: bold;
				color: #1f1f1f;
			}
		}
	}
</style>
